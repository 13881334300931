import './App.scss';
import { Home } from './pages/Home'
import { NotFound } from './pages/NotFound'
import { Component } from 'react';
import { Portfolio } from './pages/portfolio/Portfolio'

class AppContentDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_nav_page: window.location.pathname?.substring(1, (window.location.pathname.substring(1).indexOf('/') + 1) || 1000) || "Home",
      not_found_page: <NotFound />,
      navigation: [
        "Home",
        "Portfolio",
      ],
      valid_pages: {
        "Home": <Home />,
        "Portfolio": <Portfolio />,
      }
    };
  }

  getDisplayContent() {
    let selected = this.state.valid_pages[this.state.current_nav_page];
    return selected || this.state.not_found_page;
  }

  changePage(new_page) {
    this.setState({
      current_nav_page: new_page,
    });
    window.history.pushState(null, new_page, "/" + new_page)
  }

  getHeaderBar() {
    return (
      <div className='App-header'>
        {this.state.navigation.map(element => (
          <div
            key={element}
            className={'Header-button' + (this.state.current_nav_page === element ? ' selected' : '')}
            onClick={e => {
              this.changePage(element);
            }}>
            {element}
          </div>
        ))}
      </div>
    );
  }

  render(){
    return (
      <div className="App">
        {this.getHeaderBar()}
        <div className="App-body">
          {this.getDisplayContent()}
        </div>
      </div>
    );
  }
}

function App() {
  return (
    <AppContentDisplay />
  );
}

export default App;
