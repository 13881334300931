import '../App.scss';
import './Home.scss';
import colours from '../utilities/colours.scss';

export const Home = () => {
    return (
        <>
            <div className='animation-container'>
                <div className='enter-animation' />
            </div>
            <div className='home-background'>
                <svg className='background-svg-holder' width="1024" viewBox='0 0 1024 1024'>
                    <defs>
                        <clipPath id="sideBarClip">
                            <polygon points="1024,1024 384,1024, 1024,0" />
                        </clipPath>
                        <clipPath id="clip1">
                            <polygon points="1024,1024 384,1024, 1024,0" />
                        </clipPath>
                        <clipPath id="clip2">
                            <polygon points="1024,1024 512,1024, 1024,204.8" />
                        </clipPath>
                        <clipPath id="clip3">
                            <polygon points="1024,1024 640,1024, 1024,409.6" />
                        </clipPath>
                        <clipPath id="clip4">
                            <polygon points="1024,1024 768,1024, 1024,614.4" />
                        </clipPath>
                        <pattern id="starfill" viewBox="0 0 200 200" width="25%" height="25%">
                            <text x="48" y="40" fontSize="25" rotate="30" fill={colours.primaryLight} opacity="0.15">★</text>
                            <text x="130" y="180" fontSize="25" rotate="15" fill={colours.primaryLight} opacity="0.2">★</text>
                            <text x="160" y="80" fontSize="25" rotate="0" fill={colours.primaryLight} opacity="0.3">★</text>
                            <text x="100" y="120" fontSize="25" rotate="40" fill={colours.primaryLight} opacity="0.35">★</text>
                            <text x="0" y="120" fontSize="25" rotate="45" fill={colours.primaryLight} opacity="0.4">★</text>
                        </pattern>
                    </defs>
                    <polygon
                        points=""
                        fill={colours.primaryDark}
                        opacity="0.3">
                        <animate attributeName="points" dur="5s" fill="freeze" calcMode="spline" repeatCount="indefinite"
                            values="1024,1024 384,1024, 1024,0;1024,1024 384,1024, 1024,0;1024,1024 384,1024, 1024,0" />
                    </polygon>
                    <polygon
                        points="1024,1024 512,1024, 1024,204.8"
                        fill={colours.primaryDark}
                        opacity="0.3" />
                    <polygon
                        points="1024,1024 640,1024, 1024,409.6"
                        fill={colours.primaryDark}
                        opacity="0.3" />
                    <polygon
                        points="1024,1024 768,1024, 1024,614.4"
                        fill={colours.primaryDark}
                        opacity="0.3" />
                    <rect x="0" y="0" width="1024" height="1024" clipPath='url(#clip1)' fill='url(#starfill)' opacity="0.3" />
                    <rect x="0" y="0" width="1024" height="1024" clipPath='url(#clip2)' fill='url(#starfill)' opacity="0.3" />
                    <rect x="0" y="0" width="1024" height="1024" clipPath='url(#clip3)' fill='url(#starfill)' opacity="0.3" />
                    <rect x="0" y="0" width="1024" height="1024" clipPath='url(#clip4)' fill='url(#starfill)' opacity="0.4" />
                </svg>
                <div className='home-content-container'>
                    <div className='home-content'>
                        <p>
                            Enjoying the art of &nbsp;
                            <div className="scrolling-text-holder">
                            <table className="scrolling-text">
                                <tr>Game Development</tr>
                                <tr>Game Design</tr>
                                <tr>C# Programming</tr>
                                <tr>Game Development</tr>
                            </table>
                            </div>
                        </p>
                        <div className="Horizontal-buttons">
                            <a
                                className="App-link"
                                href="Portfolio"
                            >
                            Portfolio
                            </a>
                            <a
                                className="App-link"
                                href="https://github.com/PowerfulBacon"
                            >
                            Github
                            </a>
                        </div>
                        <div className='bottom-text'>
                            Created by <b>Jake Mortlock.</b>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
