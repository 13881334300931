import { Component } from "react";
import "./portfolio.scss";
import img_englightened from "../../content/portfolio/enlightened/image.png";
import img_corgeng from "../../content/portfolio/corgeng.png";
import img_ss13 from "../../content/ss13_lighting.png";
import img_tt from "../../content/portfolio/temporaltail.png";
import img_sgj from "../../content/portfolio/sgj.png";
import img_archofact from "../../content/portfolio/archofact.png";
import img_branchingfactor from "../../content/portfolio/branchingfactor.png";
import img_infiltration from "../../content/portfolio/infiltration.png";
import img_csharp from "../../content/portfolio/csharp.png";
import img_dm from "../../content/portfolio/dm.png";
import img_unity from "../../content/portfolio/unity.png";
import img_byond from "../../content/portfolio/byond.png";
import img_react from "../../content/portfolio/react.png";
import img_openGL from "../../content/portfolio/openGL.svg";


export class Portfolio extends Component
{

    constructor()
    {
        super();
        this.state = {
            currentPage: window.location.pathname?.substring(11),
            overlay: null,
        };
    }

    mainPage()
    {
        return (
            <div
                className="portfolio-page"
                style={{
                    backgroundImage: `url(${img_englightened})`
                }}
                >
                <div className="portfolio-text-container">
                    <p className="title">Hello!</p>
                    <p>
                        My name is Jake Mortlock, I am a programmer and game designer
                        based in the UK
                        who has been working on developing games since 2017.
                    </p>
                    <p>
                        I am highly proficient with C#, OpenGL and have developed an entire
                        game-engine written in C#. I have a wide range of projects to showcase
                        my skill and large code samples available on my <a href="https://www.github.com/powerfulbacon">Github</a>.
                    </p>
                    <p>
                        I also have some experience with Java and C++ and I primarilly use either Unity,
                        Godot, or a custom rendering/game engine.
                    </p>
                    <p>
                        I am currently <b>looking for work</b>, either as a game programmer or game designer. Get in contact
                        with me via my email: jakeymortlock@<div className="no-space" />gmail.com.
                    </p>
                </div>
                <div
                    className="portfolio-button-container"
                    onClick={() => {
                        window.history.pushState(null, "", "/Portfolio/projectList");
                        this.setState({
                            currentPage: "projectList",
                        });
                    }}>
                    View my projects
                </div>
            </div>
        );
    }

    projectList()
    {
        return (
            <div
                className="portfolio-page"
                style={{
                    backgroundImage: `url(${img_englightened})`
                }}
                >
                <div className="project-container">
                    <p className="project-title title">My Projects</p>
                    <a
                        className="project"
                        style={{
                            backgroundImage: `url(${img_corgeng})`
                        }}
                        href = "https://github.com/PowerfulBacon/CorgEng" >
                        <div className="darken-background">
                            CorgEng
                            <div className="language-list">
                                <img alt="csharp logo" src={img_csharp} width="10%" />
                                <img alt="OpenGL logo" src={img_openGL} width="30%" />
                            </div>
                            <ul className="normal-text">
                                <li>Modularised game engine that I designed and coded entirely in C#.</li>
                                <li>Learnt and implemented frameworks for common patterns such as ECS + Signals.</li>
                                <li>Extremely large, long-term programming project which shows off competency as a programmer.</li>
                                <li>Custom implementations for rendering, networking, content-loading, ECS and lighting.</li>
                            </ul>
                            <p className="normal-text link-fix">Click to view Github repository</p>
                        </div>
                    </a>
                    <a
                        className="project"
                        style={{
                            backgroundImage: `url(${img_englightened})`
                        }}
                        href="https://miammiam.itch.io/enlightened">
                        <div className="darken-background">
                            Enlightended
                            <div className="language-list">
                                <img alt="csharp logo" src={img_csharp} width="10%" />
                                <img alt="unity logo" src={img_unity} width="10%" />
                            </div>
                            <ul className="normal-text">
                                <li>Game design coursework using lighting and visibility as part of the mechanics.</li>
                                <li>Complex rendering pipeline with various shader effects such as a lookup palette and light blending.</li>
                                <li>Highly polished and refined gameplay, with a lot of playtesting involved.</li>
                            </ul>
                            <p className="normal-text link-fix">Click to view the itch.io page</p>
                        </div>
                    </a>
                    <a
                        className="project"
                        style={{
                            backgroundImage: `url(${img_ss13})`
                        }}
                        href="https://github.com/BeeStation/BeeStation-Hornet" >
                        <div className="darken-background">
                            Beestation - Space Station 13
                            <div className="language-list">
                                <img alt="DM logo" src={img_dm} width="10%" />
                                <img alt="Byond logo" src={img_byond} width="10%" />
                                <img alt="React logo" src={img_react} width="13%" />
                            </div>
                            <ul className="normal-text">
                                <li>Contributor to an open-source codebase of Space Station 13, becoming a maintainer, and eventually the Head-Developer.</li>
                                <li>Reviewed and updated design directions.</li>
                                <li>Wrote core performance-critical systems such as lazy map-generation, dynamic map allocation, and a multi-body physics simulation.</li>
                            </ul>
                            <p className="normal-text link-fix">Click to view Github repository</p>
                        </div>
                    </a>
                    <div
                        className="project"
                        style={{
                            backgroundImage: `url(${img_sgj})`
                        }}>
                        <div className="darken-background">
                            Southampton Game Jam 2024 Organiser
                            <ul className="normal-text">
                                <li>I was an organiser for the 2024 edition of the Southampton game jam.</li>
                                <li>Assisted with planning the event, developing software to display the jam's schedule, and obtaining funding.</li>
                            </ul>
                        </div>
                    </div>
                    <a
                        className="project"
                        style={{
                            backgroundImage: `url(${img_tt})`
                        }}
                        href="https://powerfulbacon.itch.io/temporal-tail">
                        <div className="darken-background">
                            Temporal Tail
                            <div className="language-list">
                                <img alt="csharp logo" src={img_csharp} width="10%" />
                                <img alt="unity logo" src={img_unity} width="10%" />
                            </div>
                            <ul className="normal-text">
                                <li>Puzzle game about moving back and forwards through time as well as space.</li>
                                <li>Created in 10 days for the Goedware game jam.</li>
                                <li>Winner of the best development log award.</li>
                            </ul>
                            <p className="normal-text link-fix">Click to view itch.io page</p>
                        </div>
                    </a>
                    <a
                        className="project"
                        style={{
                            backgroundImage: `url(${img_archofact})`
                        }}
                        href="https://powerfulbacon.itch.io/archofact">
                        <div className="darken-background">
                            Archofact
                            <div className="language-list">
                                <img alt="csharp logo" src={img_csharp} width="10%" />
                                <img alt="unity logo" src={img_unity} width="10%" />
                            </div>
                            <ul className="normal-text">
                                <li>Exploration game where the player views the world through a LIDAR scanner.</li>
                                <li>Created in 10 days for the Lv.99 Game Jam.</li>
                            </ul>
                            <p className="normal-text link-fix">Click to view itch.io page</p>
                        </div>
                    </a>
                    <a
                        className="project"
                        style={{
                            backgroundImage: `url(${img_branchingfactor})`
                        }}
                        href="https://powerfulbacon.itch.io/branching-factor">
                        <div className="darken-background">
                            Branching Factor
                            <div className="language-list">
                                <img alt="csharp logo" src={img_csharp} width="10%" />
                                <img alt="unity logo" src={img_unity} width="10%" />
                            </div>
                            <ul className="normal-text">
                                <li>A wave survival shooter game where you build a spaceship out of random parts.</li>
                                <li>Created in 48-hours for the Southampton Game Jam 2023.</li>
                            </ul>
                            <p className="normal-text link-fix">Click to view itch.io page</p>
                        </div>
                    </a>
                    <a
                        className="project"
                        style={{
                            backgroundImage: `url(${img_infiltration})`
                        }}
                        href="https://powerfulbacon.itch.io/infiltration">
                        <div className="darken-background">
                            Infiltration
                            <div className="language-list">
                                <img alt="csharp logo" src={img_csharp} width="10%" />
                                <img alt="unity logo" src={img_unity} width="10%" />
                            </div>
                            <ul className="normal-text">
                                <li>A stealth game where you use cheats in order to complete the level.</li>
                                <li>Created in a team of 2 for the itch.io weekly game jam.</li>
                            </ul>
                            <p className="normal-text link-fix">Click to view itch.io page</p>
                        </div>
                    </a>
                </div>
                <div
                    className="portfolio-button-container"
                    onClick={() => {
                        window.history.pushState(null, "", "/Portfolio/main");
                        this.setState({
                            currentPage: "main",
                        });
                    }}>
                    Back
                </div>
            </div>
        );
    }

    addOverlay(content)
    {
        const {
            overlay
        } = this.state;
        return (
        <>
            {content}
            {overlay && (
                <div className="portfolio-overlay">
                    <div className="portfolio-overlay-text">
                        {overlay}
                        <div
                            className="portfolio-overlay-button"
                            onClick={() => {
                                this.setState({
                                    overlay: null,
                                });
                            }}>
                            Close
                        </div>
                    </div>
                </div>
            )}
        </>
        );
    }

    render()
    {
        switch (this.state.currentPage)
        {
            case "projectList":
                return this.addOverlay(this.projectList());
            default:
                return this.addOverlay(this.mainPage());
        }
    }

}
