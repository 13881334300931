import '../App.scss';

export const NotFound = () => {
    return (
        <>
        <p>
            404: Webpage not found! :(
        </p>
        <a
            className="App-link"
            href="/"
        >
        Return Home
        </a>
    </>
    );
}
